import { Helmet } from "react-helmet";

const Services = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Services</title>
          <meta name="description" content="React application" />
        </Helmet>
        <div className="banner">
          <h2>Services We Offer</h2>
          <p>Elevating Your Experience with Excellence</p>
        </div>
        <div className="breadcum">
          Home | <b> Our Services: Tailored Solutions for Your Security and Peace of Mind</b>
        </div>
        <div className="content">
          <articles>
            <h3>What We Offer</h3>
            <article className="article-left">
              <p>Welcome to Henrima Delta Security Services & Consultancy Pvt Ltd, where our Security Guard Service goes beyond conventional protection. We offer a comprehensive range of expertise in security consulting, guard operations, and outsourced security management. Our strategic approach is built on consultation, thorough cost analysis, and a commitment to deploying the correct resources and equipment tailored to each facility's unique needs. This ensures not only adequate but also cost-effective protection for our clients.</p>
            </article>
            <article>
              <h4>Expert Security Consulting</h4>
              <p>At Henrima Delta, we understand that each client's security requirements are distinct. Through in-depth consultations, we collaborate with our clients to understand their specific needs and concerns. Our security consulting services involve a meticulous cost analysis to determine the optimal number and type of resources and equipment required for effective protection.</p>
              <h4>Systematic Approach to Security Operations</h4>
              <p>We adopt a systematic approach to managing all events and daily business operations. Henrima Delta Security Services & Consultancy Pvt Ltd ensures that security measures are implemented in an effective and decisive manner, providing a seamless protective shield for our clients.</p>
              <h4>Customized Reporting Mechanism</h4>
              <p>Our commitment to transparency and client satisfaction extends to our reporting mechanisms. Clients have the ability to address any issue through a customized reporting system. This ensures that concerns are promptly attended to, fostering a proactive approach to security management.</p>
              <h4>Monthly Business Reviews</h4>
              <p>To continuously enhance our services and address evolving needs, we encourage our client partnerships to utilize our monthly business reviews. These reviews employ a bespoke set of key performance indicators (KPIs) designed to identify and measure the most crucial aspects of the services provided. This proactive approach allows us to refine our strategies and deliver optimal security solutions.</p>
              <h4>Immediate Issue Resolution</h4>
              <p>While our monthly reviews provide a structured overview, we recognize that immediate issues may arise. It is our policy at Henrima Delta Security Services & Consultancy Pvt Ltd to address and resolve issues as and when they occur. Our dedicated team is committed to implementing effective solutions promptly, ensuring the ongoing safety and satisfaction of our clients.</p>
              <p>At Henrima Delta, we don't just provide security services; we forge partnerships, offering tailored solutions that evolve with your needs. Trust us to safeguard your assets and operations with a strategic, systematic, and client-focused approach to security management.</p>
            
              <h3>Henrima Facility Management Services</h3>
              <p>where we specialize in providing a comprehensive suite of solutions to meet the diverse needs of your infrastructure. Our Facility Management Services cover a broad spectrum of areas to ensure the seamless functioning of your facilities. Explore the various services we offer:</p>
              <h4>Electrical Services</h4>
              <p>Our Electrical Services are designed to keep your electrical systems in optimal condition. From routine maintenance to emergency repairs, our team of skilled professionals ensures the uninterrupted operation of your electrical infrastructure.</p>
              <h4>Mechanical Services</h4>
              <p>Henrima Facility Management Services includes Mechanical Services to address the maintenance and operation of mechanical systems. Our team is equipped to handle a variety of mechanical components to keep your facilities running smoothly.</p>
              <h4>Plumbing Services</h4>
              <p>Maintaining an efficient plumbing system is crucial for any facility. Our Plumbing Services encompass routine inspections, repairs, and installations to ensure a reliable and functional plumbing infrastructure.</p>
              <h4>Landscaping Services</h4>
              <p>Create an inviting and aesthetically pleasing environment with our Landscaping Services. Our team is dedicated to enhancing the visual appeal of your outdoor spaces through strategic landscaping solutions.</p>
              <h4>Lift Operating Services / Vehicle Operating Services</h4>
              <p>Ensure the smooth operation of lifts and vehicles with our specialized services. We offer comprehensive maintenance and operation solutions to guarantee the safety and functionality of these essential components.</p>
              <h4>Civil Maintenance Services</h4>
              <p>Our Civil Maintenance Services cover a wide range of activities, including repairs, renovations, and construction projects. We prioritize the structural integrity of your facilities, ensuring they meet safety and regulatory standards.</p>
              <h4>Comprehensive Facility Management</h4>
              <p>Henrima Facility Management Services goes beyond individual services. We offer comprehensive solutions that encompass all aspects of facility maintenance. Our integrated approach ensures that your facilities are not only well-maintained but also operate efficiently.</p>
              <h4>Tailored Solutions</h4>
              <p>We understand that every facility is unique. Our Facility Management Services are tailored to meet the specific needs of your organization. Whether you require ongoing maintenance, emergency repairs, or strategic planning, we have the expertise to deliver customized solutions.</p>
              <h4>Professional Team</h4>
              <p>Our team of professionals is highly skilled and experienced in their respective domains. From electricians and plumbers to landscapers and civil engineers, we bring a diverse skill set to cater to the multifaceted needs of your facilities.</p>
              
            </article>
            <article>
              <h3>Henrima Delta Housekeeping Services</h3>
              <p> where cleanliness meets professionalism. Our commitment to maintaining a pristine environment is reflected in every aspect of our service. Here's how we ensure your spaces remain spotless and welcoming:</p>
              <h4>Eco-Friendly Practices</h4>
              <p>At Henrima Delta, we prioritize the use of eco-friendly products and quality equipment in our housekeeping services. Our commitment to sustainability goes hand in hand with our dedication to providing a clean and healthy environment.</p>
              <h4>Thorough Attention to Detail</h4>
              <p>As professional housekeeping service providers, we understand that cleanliness is in the details. That's why we pay thorough attention to all the nooks and corners, leaving no space untouched. Our meticulous approach ensures every inch of your premises is impeccably clean.</p>
              <h4>Unexpected Results</h4>
              <p>Our goal is to deliver results that exceed expectations. Whether it's the reception area, high-traffic zones, janitorial spaces, bathrooms, or the kitchen/pantry section, we handle all cleaning demands with precision and care. Expect the unexpected when it comes to the cleanliness and tidiness of your space.</p>
              <h4>Comprehensive Cleaning</h4>
              <p>From the moment your guests or clients enter, we extend our cleaning services to reception areas, ensuring a positive first impression. High-traffic areas receive special attention to maintain a fresh and inviting atmosphere. Janitorial spaces are meticulously cleaned to uphold hygiene standards, while bathrooms and kitchen/pantry sections are sanitized for a pristine environment.</p>
              <p>Henrima Delta Housekeeping Services is not just about cleaning; it's about creating an ambiance of cleanliness and order. Trust us to transform your spaces into immaculate, welcoming environments through our professional and eco-conscious housekeeping practices. Your satisfaction is our top priority, and we are dedicated to exceeding your expectations with every cleaning service we provide.</p>
            
              <h3>Manpower Outsourcing Services</h3>
              <p>At Henrima Consultancy, we specialize in recruiting skilled professionals across a broad range of categories. Our expertise extends to:
                <dl>
                  <li>Medical Services/ Engineering Services</li>
                  <li>Office Staffing/ Sales Agents for Showrooms/ Delivery Agents</li>
                  <li>Computer Operators/ Machine Operators/ HVAC Technicians</li>
                  <li>Office Boy/ Assistance/ Attender/ Receptionist</li>
                  <li>Contract Labour/ Factory Workers</li>
                </dl></p>
                <h3>Variety of Outsourcing Services</h3>
                <p>Our commitment to meeting your specific staffing needs is reflected in our diverse outsourcing services:
                <ul>
                    <li> <b>Contract Staffing</b> : Providing skilled professionals for specific project durations.</li>
                    <li> <b>Temporary Staffing</b> : Offering flexible staffing solutions for short-term requirements.</li>
                    <li> <b>Payroll Outsourcing</b> : Streamlining your payroll processes for seamless operations.</li>
                  </ul></p>

                  </article>
                <article>
                  <h4>Industry-Specific Recruitment</h4>
                  <p>Henrima Consultancy recruits across various industry segments, catering to multinational corporations and leading Indian business houses. Over the past decade, we have played a pivotal role in shaping the careers of over 60,000 professionals.</p>
                  
                   <h4>Impressive Track Record</h4>
                  <p>Our achievements are evident in the numbers – we've successfully placed more than 75,000 candidates and counting. Henrima Consultancy takes pride in its long-standing relationships within the industry, with 75% of our revenue stemming from existing clients. This speaks volumes about the satisfaction and trust our clients place in our services.</p>
                  
                
                  <h4>Collaborative Approach</h4>
                  <p>We believe in fostering collaborative relationships with our clients, understanding their unique requirements, and delivering tailored solutions. Our commitment goes beyond recruitment; we aim to contribute to the success and growth of your organization by providing the right talent at the right time.</p>
                  </article>
                <article>
                  <p>Henrima Consultancy is more than a manpower outsourcing agency; we are your strategic partner in building efficient, skilled, and dynamic teams. Trust us to navigate the intricacies of recruitment, ensuring that your workforce aligns seamlessly with your organizational goals. Your success is our priority, and we are proud to be the helping hands in shaping successful careers and fostering lasting partnerships</p>
                  <p>Henrima Facility Management Services is your one-stop solution for maintaining and enhancing the functionality of your facilities. Trust us to deliver reliable, efficient, and cost-effective services to ensure the longevity and performance of your infrastructure</p>
            </article>
            <article className="one-forth">
              <h3>List of Security Services We Offer</h3>
              <p>At Henrima Delta, we take pride in providing a wide array of security services tailored to meet the diverse needs of our clients. Our dedicated team of professionals is committed to ensuring the safety and security of your assets, offering a range of specialized services that go beyond conventional security measures. Here is a detailed list of the services we offer:</p>
              <h4>Security Guarding Service</h4>
              <p>Our trained and vigilant security guards are deployed to safeguard your premises, providing a visible deterrent to potential threats.</p>
              <h4>Armed / Unarmed Guarding</h4>
              <p>Choose from our armed or unarmed guards, depending on your security requirements and the level of protection needed.</p>
              <h4>Security Manager (SM)</h4>
              <p>Our Security Managers oversee and coordinate security operations, ensuring efficiency and adherence to protocols.</p>
              <h4>Security Officer (SO)</h4>
              <p>Experienced Security Officers ensure the implementation of security measures and the safety of your premises.</p>
              <h4>Assistant Security Officer</h4>
              <p>Supporting our Security Officers, Assistant Security Officers play a crucial role in maintaining a secure environment.</p>
              <h4>Elite Guard</h4>
              <p>For heightened security needs, our Elite Guards are trained to handle complex and high-risk situations.</p>
              <h4>Gate Guard</h4>
              <p>Our Gate Guards manage access control, ensuring only authorized individuals enter your premises.</p>
              <h4>VIP Escort Service</h4>
              <p>Providing discreet and professional VIP escort services to ensure the safety of high-profile individuals.</p>
              <h4>Cash Loading & Transportation Service</h4>
              <p>Secure transportation of cash with our specialized services to minimize the risk of theft or loss.</p>
              <h4>Personal Body-Guard Service</h4>
              <p>Tailored personal security solutions to safeguard individuals in various settings.</p>
              <h4>Private Investigation Service</h4>
              <p>Confidential and thorough private investigation services to gather information and mitigate risks.</p>
              <h4>Bill Collection Services</h4>
              <p>Efficient and secure bill collection services for businesses and financial institutions.</p>
              <h4>Property Development & Real Estates</h4>
              <p>Security solutions for property development projects and real estate properties.</p>
              <h4>Litigation Property Settlement Services</h4>
              <p>Ensuring the security of properties involved in litigation and settlement processes.</p>
              <h4>Training Center Services & Establishments</h4>
              <p>Comprehensive security training programs for individuals and organizations, promoting safety awareness.</p>
              <h4>Flag Hosting for VIP Ceremonies & National Functions</h4>
              <p>Specialized services for the dignified hosting of flags during VIP ceremonies and national functions.</p>
              <h4>Parking Yard Maintenance & Security</h4>
              <p>Securing parking yards and providing maintenance services to enhance safety.</p>
              <h4>CCTV Cameras, Security Systems & Solutions</h4>
              <p>Installation and maintenance of cutting-edge CCTV cameras and security systems for robust surveillance.</p>
              <p>At Henrima Delta, our commitment is to deliver unmatched security solutions tailored to your unique needs. Contact us to discuss your requirements and let us create a customized security plan to ensure your peace of mind.</p>
            </article>
          </articles>
        </div>
      </>
    );
  };
  
  export default Services;