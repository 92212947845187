import { Helmet } from "react-helmet";
import "./Contact.sass";


const Contact = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Us</title>
          <meta name="description" content="React application" />
        </Helmet>
        <div className="banner">
          <h2>Contact Us</h2>
          <p>Gratitude in Connection: Thank You for Reaching Out to Us!</p>
        </div>
        <div className="breadcum">
          Home | <b>Contact</b>
        </div>
        <div className="content">
          <div className="leftPart">
            <div className="title">
              Contact Info
            </div>
            <div className="telephone grid-contact-info">
              <span><i className="fa fa-volume-control-phone"></i></span>
              <h5> Telephone <span>+91(44)28151837, +91 9841092828</span></h5>
            </div>
            <div className="Mail grid-contact-info">
              <span><i className="fa fa-envelope"></i></span>
              <h5> Email<span>henrimadelta10@gmail.com</span></h5>
            </div>
            <div className="address grid-contact-info">
              <span><i className="fa fa-map-marker"></i></span>
              <h5> Address<span>50, Vanniyar st, Choolaimedu, Chennai - 600094</span></h5>
            </div>
            <div className="social-icons">
              <ul>
                <li>
                  <i className="fa fa-facebook"></i>
                </li>
                <li>
                  <i className="fa fa-twitter"></i>
                </li>
                <li>
                  <i className="fa fa-instagram"></i>
                </li>
                <li>
                  <i className="fa fa-linkedin"></i>
                </li>
                <li>
                  <i className="fa fa-whatsapp"></i>
                </li>
              </ul>
            </div>
          </div>
          <div className="rightPart">
            <div className="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124368.67882526574!2d80.09957337308906!3d13.066119500000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52664ad8739f73%3A0xca9bc8bccb1f1754!2sHenrima%20Delta%20Security%20Service%20%26%20Consultancy%20Pvt%20Ltd.!5e0!3m2!1sen!2sus!4v1704997782157!5m2!1sen!2sus" width="100%" height="460" style={{ border:0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default Contact;