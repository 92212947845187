import { useState } from "react";
import logo from "../assets/logo-henrima.png";
import { Outlet, Link, useLocation } from "react-router-dom";

const Layout = () => {
    const pathname = useLocation().pathname
    const [isNavExpanded, setIsNavExpanded] = useState(false)
  return (
    <>
    
    <div className="App">
      <header className="App-header">
        
      <img src={logo} className="App-logo" alt="logo" />
      <h1>Henrima
        <label><span class="group">India</span> <i class="fa fa-circle" aria-hidden="true"></i> Protecting Commerce<sup><i class="fa fa-trademark" aria-hidden="true"></i></sup></label>
      </h1>
      <nav className={
          isNavExpanded ? "navigation-menu-expanded" : "navigation-menu"
        }
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}>
        <ul>
          <li>
            <Link className={`${pathname === '/' ? 'is-active' : ''}`}  aria-haspopup="true" to="/">Home</Link>
          </li> 
          <li>
            <Link className={`${pathname === '/about' ? 'is-active' : ''}`}  aria-haspopup="true" to="/about">About Us</Link>
          </li>
          <li>
            <Link className={`${pathname === '/services' ? 'is-active' : ''}`} aria-haspopup="true" to="/services">Services</Link>
          </li>
          <li>
            <Link className={`${pathname === '/clients' ? 'is-active' : ''}`} to="/clients">Clients</Link>
          </li>
          <li>
            <Link className={`${pathname === '/contact' ? 'is-active' : ''}`} to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
      <button
        className="menu-icon fa fa-bars"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      ></button>
        
      </header>
      <Outlet />
      <footer>
        <p>© 2018-2023. All rights reserved</p>
      </footer>
    </div>
      
    </>
  )
};

export default Layout;