import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import banner1 from "../../assets/banner1.jpg";
import banner2 from "../../assets/banner2.jpg";
import banner3 from "../../assets/banner3.jpg";
import home1 from "../../assets/henrima-intro.png";
import home2 from "../../assets/security-services.png";
import "./Home.sass";
import { Helmet } from "react-helmet";

const Home = () => {
  const banner = [
    {
      name: banner1,
      header: "Inspiring leadership",
      span: "innovation.",
      content: "Hire. Train. Retain.",
    },
    {
      name: banner2,
      header: "Guarding Your Peace of Mind: ",
      span: "Trusted Security Solutions.",
      content: "Guarding with Precision: Total Security",
    },
    {
      name: banner3,
      header: "Improving workplace",
      span: "Productivity.",
      content: "Safety Sentinel: Expert Protection",
    },
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Security Services in Chennai - Henrima Delta</title>
        <meta name="description" content="React application" />
      </Helmet>
      <Carousel
        autoPlay
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        infiniteLoop={true}
      >
        {banner.map((item) => (
          <div>
            <img src={item.name} alt="Banner" />
            <div className="legend">
              <h2>
                {item.header}
                <span> {item.span} </span>
              </h2>
              <p> {item.content}</p>
            </div>
          </div>
        ))}
      </Carousel>
      <section className="content-home">
        <h3>
          <span>Welcome to </span>
          <br />
          HENRIMA DELTA SECURITY SERVICES & CONSULTANCY{" "}
        </h3>
        <p>
          {" "}
          – a global leader in Security and Risk Management. Our mission is to
          protect and secure your valuable assets through cutting-edge
          operational security programs designed to mitigate risks, prevent
          losses, and optimize cost savings. With strategically located offices
          in Asia, Europe, and America, we operate on an international scale,
          staying ahead of criminal modus operandi to offer unparalleled
          security and consulting services.
        </p>
        <div className="twopart">
          <h4>
            3 pcs of <span>Protection</span>
          </h4>
          <p>
            At Henrima Delta Security Services & Consultancy, we understand that
            safeguarding your assets is not just about providing security; it's
            about delivering comprehensive risk management solutions. With a
            commitment to excellence, our team of seasoned professionals ensures
            that your organization is shielded from potential criminal threats,
            operational disruptions, and the loss or theft of assets.{" "}
          </p>
          </div>
          <div className="twopart"><img className="img" src={home1} alt="" /></div>
          <div className="twopart">
          <h4>Global Presence</h4>
          <p>
            Our extensive network spans three continents – Asia, Europe, and
            America. This global footprint allows us to stay attuned to evolving
            security landscapes worldwide. By maintaining a presence in key
            regions, we position ourselves to proactively address emerging
            threats and provide timely, tailored solutions to our clients.
          </p>
          </div>
          <div className="twopart">
          <h4>Operational Excellence</h4>
          <p>
            We pride ourselves on delivering front-end operational security
            programs that go beyond conventional measures. Our holistic approach
            involves not only safeguarding your premises but also conducting
            in-depth on-site security analyses. These analyses identify and
            highlight vulnerable areas of risk within your organization, paving
            the way for customized security solutions.
          </p>
          </div>
          <div className="twopart">
          <h4>Security Analysis and Consultation</h4>
          <p>
            At Henrima Delta, we believe in a proactive security strategy. Our
            experts can come to you anywhere in the world to conduct a thorough
            on-site security analysis. This process involves a meticulous
            examination of your organization's infrastructure, operations, and
            potential vulnerabilities. We then provide a detailed report,
            offering insights and recommendations to fortify your security
            posture.
          </p>
          </div>
          <div className="twopart">
          <h4>Customized Solutions</h4>
          <p>
            Recognizing that each organization has unique security requirements,
            we specialize in tailoring solutions to suit your specific needs.
            Our team collaborates with you to understand your challenges, assess
            potential risks, and develop a bespoke security plan. Whether you
            operate in a high-risk environment or a more conventional setting,
            we have the expertise to create a solution that aligns with your
            organizational objectives.
          </p>
          </div>
          <div className="twopart">
          <h4>Key Services</h4>
          <ol>
            <li>
              <h5>Security Guarding Services</h5>
              <p>
                  Our team of highly trained security personnel is dedicated to
                  ensuring the safety and security of your premises. From access
                  control to perimeter monitoring, our security guards are
                  equipped to handle diverse challenges, providing a visible
                  deterrent to potential threats.
              </p>
            </li>
            <li>
              <h5>Risk Assessment and Management</h5>
              <p>
                  We conduct comprehensive risk assessments to identify
                  potential threats to your organization. Our experts analyze
                  data, assess vulnerabilities, and develop risk management
                  strategies to fortify your defenses.
              </p>
            </li>
            <li>
              <h5>Consulting Services</h5>
              <p>
              Henrima Delta goes beyond traditional security services by
              offering expert consulting services. Whether you need assistance
              in developing security policies, crisis management planning, or
              training programs, our consultants provide invaluable insights to
              enhance your overall security framework.
              </p>
            </li>
            <li>
              <h5>Technology Integration</h5>
              <p>
                  Stay ahead of the curve with our technology integration
                  services. We leverage the latest advancements in security
                  technology, including surveillance systems, access control,
                  and biometric solutions, to enhance the effectiveness of your
                  security measures.
                </p>
            </li>
          </ol>
          </div>
          <div className="twopart">
          <h4>Why Choose Henrima Delta Security Services & Consultancy?</h4>
          <ol>
            <li>
              <h6>Expertise</h6>
              <p>Our team comprises seasoned professionals with
              extensive experience in security and risk management.
              </p> 
            </li>
            <li>
              <h6>Global Reach</h6>
              <p> With offices in Asia, Europe, and America,
              we offer a truly global perspective on security challenges.
              </p>
            </li>
            <li>
              <h6>Custom Solutions</h6>
              <p>We understand that one size does not
              fit all. Our solutions are tailored to meet the unique needs of
              each client.
              </p> 
            </li>
            <li>
              <h6>Proactive Approach</h6>
              <p>Our proactive security strategies
              involve anticipating threats and implementing measures to prevent
              incidents before they occur.
              </p>
            </li>
            <li>
              <h6>Technology Integration</h6>
              <p>We stay at the forefront of
              security technology, integrating the latest innovations to enhance
              your security infrastructure.
              </p>
            </li>
          </ol>
          </div>
          <div className="twopart"><img className="img" src={home2} alt="" /></div>
          <div className="twopart">
          <h4> Our Services</h4>
          <ul>
            <li>
              <span class="fa fa-industry" aria-hidden="true"></span>In
              Industrial establishments and factories.
            </li>
            <li>
              <span class="fa fa-desktop" aria-hidden="true"></span> Info
              Technology Parks
            </li>
            <li>
              <span class="fa fa-user" aria-hidden="true"></span> Gym parks &
              ATMS/ Bank
            </li>
            <li>
              <span class="fa fa-building" aria-hidden="true"></span> Corporate
              sectors & Commercial complexes.
            </li>
            <li>
              <span class="fa fa-hospital-o" aria-hidden="true"></span> Hotels,
              Hospitals and residential colonies.{" "}
            </li>
            <li>
              <span class="fa fa-briefcase" aria-hidden="true"></span> Ware
              house, Yards, Projects & Construction sites.
            </li>
            <li>
              <span class="fa fa-graduation-cap" aria-hidden="true"></span>{" "}
              Educational institutions & Engineering Sectors.
            </li>
          </ul>
        </div>
        <div className="twopart"></div>
      </section>
      <section className="down-banner">
          <h4>
            Whether we play a large or small role, by working together we
            achieve our objectives.
          </h4>
      </section>
    </>
  );
};

export default Home;
