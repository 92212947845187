import { Helmet } from "react-helmet";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('../../assets/clients/', false, /\.(jpg)$/));

const Clients = () => {
  const clientCard = [
    { name: "ACACIA HOMES & CONSTRUCTIONS PVT LTD", img: "0", content: "SHAMBHAVI- GUDUVANCHERY", location: ['facebook', 'twitter'] },
    { name: "AGARWAL’S EYE HOSPITALS PVT LTD", img: "1", content: "ASHOK NAGAR, ANNA NAGAR, NUNGAMBAKKAM, NEW WASHERMANPET, NAGANALLUR, PORUR, PERIYAR NAGAR, GOPALAPURAM, TRIPLICANE,THIRUVALLUR, TAMBARAM, VELACHERY, EGMORE, ERC ALWARPET, DHARMAPURI, ERODE, HOSUR, KRISHNAGIRI, KANCHIPURAM, KUMBAKONAM, SALEM, VELLORE, VILLUPURAM", location: ['facebook', 'twitter'] },
    { name: "CITY UNION BANK - ATM", img: "8", content: "ARANI, VIZARAM,VELLORE, GUDIYATHAM, AGARAMCHERRY, CHITTOOR, KALLAKURUCHI, SATHUVACHERRY, CHEYYAR, RANIPET, THIRUPATHUR, AMBUR, KATPADI", location: ['facebook', 'twitter'] },
    { name: "REACH MANAGEMENT CONSULTANCY PVT LTD", img: "2", content: "", location: ['facebook', 'twitter']},
    { name: "FLAIR MANAGEMENT PVT LTD", img: "3", content: "", location: ['facebook', 'twitter'] },
    { name: "PHOENIX SHOPPING MALL", img: "4", content: "", location: ['facebook', 'twitter'] },
    { name: "SEAL INFOTECH PVT LTD", img: "5", content: "(VENKATANARAYANA ROAD), T NAGAR", location: ['facebook', 'twitter'] },
    { name: "ROSE JEWELLERY", img: "6", content: "PARK TOWN", location: ['facebook', 'twitter'] },
    { name: "BEACH HOUSE", img: "7", content: "PARRYS", location: ['facebook', 'twitter'] },
    { name: "SPOON BILL PVT LTD", img: "9", content: "", location: ['facebook', 'twitter'] },
    { name: "GRT GRAND DAYS LTD", img: "10", content: "", location: ['facebook', 'twitter']},
    { name: "MY FORTUNE HOTELS", img: "10", content: "", location: ['facebook', 'twitter'] },
    { name: "KALYAN JEWELLERS", img: "10", content: "", location: ['facebook', 'twitter'] },
    { name: "HOTEL PANDIAN", img: "10", content: "EGMORE", location: ['facebook', 'twitter'] },
    { name: "PANDIYAN MD HOUSE", img: "10", content: "KILPAUK", location: ['facebook', 'twitter'] },
    { name: "HOTEL VIJAY PARK", img: "10", content: "ARUMBAKKAM", location: ['facebook', 'twitter'] },
    { name: "SASTHA ENGINEERING COLLEGE", img: "10", content: "CHEMBARAMPAKKAM", location: ['facebook', 'twitter'] },
    { name: "MEDOPHARM PVT LTD", img: "10", content: "GUDUVANCHERY", location: ['facebook', 'twitter'] },
    { name: "SHRINE BASILICA OF OUR LADY OF HEALTH", img: "10", content: "VAILANKANNI", location:  ['facebook', 'twitter']  },
    { name: "NATIONAL TEST HOUSE", img: "10", content: "CSIR, THARAMANI", location:  ['facebook', 'twitter']  },
    { name: "HOTEL THE PARK", img: "10", content: "", location: ['facebook', 'twitter'] },
    { name: "ZISS ENTERPRISES", img: "10", content: "KILPAUK,MD RESIDENCE - T.NAGAR", location:  ['facebook', 'twitter']  },,
    { name: "ZISS FOODS AND BEVERAGES LLP", img: "10", content: "NUNGAMBAKKAM", location: ['facebook', 'twitter'] },
    { name: "VOORA CONSTRUCTIONS", img: "10", content: "KORUKKUPET", location: ['facebook', 'twitter'] },
    { name: "SKY CITY APARTMENT", img: "10", content: "VANAGARAM", location: ['facebook', 'twitter'] },
    { name: "COURTYARD BY MARRIOT", img: "10", content: "TEYNAMPET", location: ['facebook', 'twitter'] },
    { name: "SRI SANKESHWARA FLAT OWNER'S ASSOCIATION", img: "10", content: "VEPPERY", location: ['facebook', 'twitter']},
    { name: "CHOLA SHERATON", img: "10", content: "R.K.SALAI", location: ['facebook', 'twitter'] },
    { name: "LEELA GROUP OF COMPANIES PVT LTD", img: "10", content: "", location: ['facebook', 'twitter'] },    
    { name: "CHENNAI CITY CENTER", img: "10", content: "R.K.SALAI", location: ['facebook', 'twitter'] },
    { name: "MURUGAPPA GROUPS", img: "10", content: "MAHENDRA CITY, KOTTURPURAM,(CECL) RANIPET", location: ['facebook', 'twitter'] },
    { name: "FRANCO INDIAN PHAMACEUTCALS", img: "10", content: "MARAIMALAINAGAR", location: ['facebook', 'twitter'] },
    { name: "CELKON IMPEX PVT LTD", img: "10", content: "GOPALAPURAM", location: ['facebook', 'twitter'] },
    { name: "CBTL, PHOENIX MARKETCITY", img: "10", content: "VELACHERY", location: ['facebook', 'twitter'] },
    { name: "HOTEL MALAYASIA INN", img: "10", content: "T. NAGAR", location: ['facebook', 'twitter'] },
    { name: "LIFT AND SHIFT", img: "10", content: "NEW TOWN ,MANALI", location: ['facebook', 'twitter'] },
    { name: "MM INDUSTRIES", img: "10", content: "MANALI", location: ['facebook', 'twitter'] },
    { name: "GAUGE & TOOL", img: "10", content: "GUINDY", location: ['facebook', 'twitter'] },
    { name: "SRINIVASA ILLAM, (THIRUPURA CHITS (P) LTD)", img: "10", content: "SAIDAPET", location: ['facebook', 'twitter'] },
    { name: "KOBE SIZZLERS", img: "10", content: "(PHOENIX MARKET CITY)- VELACHERY", location: ['facebook', 'twitter'] },
    { name: "SAARAL RESIDENCY", img: "10", content: "EAST MOGAPPAIR", location: ['facebook', 'twitter'] },
    { name: "CENTRALISE BUILDING", img: "10", content: "R.A.PURAM,KOTTUR", location: ['facebook', 'twitter'] },
    { name: "OM SAKTHI PLAZA", img: "10", content: "KILPAUK", location: ['facebook', 'twitter'] }
  ];
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Best Security Services in Chennai - Henrima Delta</title>
          <meta name="description" content="React application" />
        </Helmet>
        <div className="banner">
          <h2>Client Corner</h2>
          <p>Building Relationships, Delivering Excellence</p>
        </div>
        <div className="breadcum">
          Home | <b> Client Show Case: Your Gateway to Exceptional Service and Collaboration</b>
        </div>
        <div className="content">
          <article>
            <h3>Our Esteemed Clients</h3>
            <p>we take pride in showcasing the valued partners who have entrusted us with their business. Our commitment to excellence and unwavering dedication has allowed us to build lasting relationships across various industries. Here's a glimpse of the esteemed clients we've had the privilege to collaborate with:</p>
          </article>
          <section className="clientList">
            {clientCard.map((item) => (
            <div className="cards">
              <div className="card-header"><img src={images[item.img]} alt="client-img" /></div>
              <div className="card-body">
                <h4>{item.name}</h4>
                <p>{item.content}</p>
              </div>
              <div className="card-footer"><i className={'fa fa-'+ item.location[0] +''}> </i> <i className={'fa fa-'+item.location[1] +''}> </i></div>
            </div>
            ))}
            
          </section>
        </div>
      </>
    );
  };
  
  export default Clients;