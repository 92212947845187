import { Helmet } from "react-helmet";
import aboutRight from "../../assets/training.png"
const About = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Apartment Security in Chennai - Henrima Delta</title>
          <meta name="description" content="React application" />
        </Helmet>
        <div className="banner">
          <h2>About Henrima Delta</h2>
          <p>Speciality in Security Guard Services</p>
        </div>
        <div className="breadcum">
          Home | <b> About Henrima Delta Security Services & Consultancy</b>
        </div>
        <div className="content">
          
          <articles>
            <h3>Who We are?</h3>
            <div className="three-forth">
              <img className="img" alt="" />
            </div>
            
            <div className="one-forth">
              <img className="img stick" src={aboutRight} width={250} alt="" />
            </div>
            <article className="article-left">
              <p>Welcome to Henrima Delta Security Services and Consultancy, a distinguished security and risk management company with a rich legacy dating back to 1950. Founded by Mr. Anderson in America, our firm has grown exponentially and extended its footprint across European, Asian, African, and other countries. In 2006, we established our presence in India and Malaysia, with our headquarters located at No: 397, Precision Plaza, Anna Salai, Teynampet, Chennai – 600018, India. Operating under the Companies Act 1961, we ensure compliance with various regulations such as the Minimum Wages Act 1948, Employees State Insurance Act 1948, Payment of Wages Act 1936, Maternity Benefit Act 1961, The Factories Act 1948, and more.</p>
              <p>Our commitment to excellence is further evidenced by our adherence to the ISO 9001 standards, and our private security agency license is issued by the Tamil Nadu Inspector General of Police Welfare Authority and the Government of Tamil Nadu.</p>
            </article>
            <article>
              <h3>Henrima Delta Security Services – A Brief Overview</h3>
              <h4>Established Legacy</h4>
              <p>With a foundation laid in 1950 by Mr. Anderson, Henrima Delta Security Services and Consultancy has evolved into a global force in the security and risk management sector.</p>
              <h4>Global Presence</h4>
              <p>Operating in America, European, Asian, and African countries, our reach extends far and wide. Since 2006, we have been contributing to the security landscape in India and Malaysia.</p>
              <h4>Compliance and Certification</h4>
              <p>Operating under the Companies Act 1961, our practices adhere to various regulatory acts, including the Minimum Wages Act 1948, Employees State Insurance Act 1948, and ISO 9001 standards. We hold a private security agency license issued by the Tamil Nadu Inspector General of Police Welfare Authority.</p>
            
            <h3>Henrima Delta Security – Ensuring Your Safety</h3>
            <p>At Henrima Delta Security, we understand the importance of providing not just security services but a sense of assurance and peace of mind. Our motto revolves around delivering excellent and flawless services to our clients, alleviating their security concerns and challenges.</p>
            <h3>Comprehensive Training Program</h3>
            <p>Our commitment to preventive measures is reflected in our comprehensive training programs. Rather than focusing solely on corrective measures, our training covers preventive measures to eliminate or minimize theft, pilferage, connivance, espionage, sabotage, and more.</p>
            
              <h3>Client-Centric Approach</h3>
              <p>Henrima Group is committed to delivering exceptional services tailored to meet the unique requirements of our clients. Our personalized, high-quality, and cost-efficient solutions reflect our dedication to exceeding expectations.</p>
            <h3>Expanding Our Services</h3>
            <p>We are continuously expanding our services to enhance your protection and provide you with unparalleled peace of mind. As a trusted partner in security and risk management, Henrima Delta Security is poised to meet the evolving needs of our clients.</p>
            </article>
            <article>
              <h3>Our Services</h3>
              <p>At Henrima Delta Security Services, we take pride in offering a diverse range of services to meet the unique needs of our clients.</p>
              <h4>1. Security Services</h4>
              <p>We provide energetic and comprehensive security services worldwide. Our team is dedicated to eliminating or minimizing risks, covering preventive measures to ensure the safety and security of your assets.</p>
              <h4>2. Housekeeping Services</h4>
              <p>In addition to security, we offer housekeeping services, ensuring a clean and organized environment for your premises.</p>
              <h4>3. Supply Services</h4>
              <p>Our offerings extend beyond security to include branded stationary and computer accessories supply services.</p>
              <h4>4. Travel Services</h4>
              <p>Henrima Group facilitates travel services to cater to the diverse needs of our clients.</p>
              <h4>5. Equipment Supply Services</h4>
              <p>We specialize in supplying security and housekeeping equipment to enhance the overall safety measures at your facility.</p>
              <h4>6. Xerox Machine Services (Rental Basis)</h4>
              <p>Our comprehensive services also cover Xerox machine contracts on a rental basis, ensuring your office equipment needs are met.</p>
            </article>
            
            <article>
              <h3>Contact Us for Unmatched Security Services</h3>
              <p>Secure your organization with Henrima Delta Security Services and Consultancy. For inquiries, consultations, or to schedule our services, contact us at contact@henrima.in or visit our Contact Page.</p>
              <p>Henrima Delta Security Services and Consultancy stands as a beacon of excellence in the security and risk management industry. With a legacy dating back to 1950, a global presence, and a commitment to client satisfaction, we are your trusted partner in safeguarding what matters most. Choose us for comprehensive security solutions, personalized services, and unwavering dedication to your safety. At Henrima Delta Security, your peace of mind is our priority.</p>
            </article>
            <article><h3>Special Task Force Patrolling Team</h3>
            <p>To address high-risk areas prone to residential and business robberies, we deploy a special task force patrolling team. This strategic measure allows us to provide a quick response in times of urgent assistance, ensuring the safety of your assets.</p>
            </article>
            <article className="mission article-left">
              <h3>Our Mission</h3>
              <p><i className="fa fa-quote-left"></i> At Henrima Delta Security Services and Consultancy, our mission is to consistently deliver highly effective and standardized security services, housekeeping services, and various supply solutions that precisely align with the diverse demands and requirements of our valued customers. We are dedicated to basing our services on the specific needs of our clients, ensuring that we provide not only the highest quality materials but also foster long-term relationships through our commitment and understanding of their unique requirements.</p>
              <p>Our pledge is to uphold these principles with unwavering dedication, promising to deliver specialized, personalized customer relationship services that go beyond expectations. At Henrima Delta, we are driven by our motto: the satisfaction of our customers. This commitment serves as the cornerstone of our mission, motivating us to consistently provide our best services at all times</p>
            </article>
            <article className="article-left">
              <h3>MANAGEMENT AND ADMINISTRATION</h3>
              <p>At Henrima Delta Security Services & Consultancy, we actively seek opportunities and support from each of our valuable customers to continually enhance the quality of our services. Our guiding principle is rooted in our motto – providing better service to our customers. Upholding ethical standards and following a moral compass, we also place great faith in the principles of goodwill and divine guidance.</p>
              <p>Our commitment to excellence is reflected in the composition of our workforce. We take pride in offering young, energetic, and well-trained professionals who are highly qualified to provide top-notch security and housekeeping services. To ensure fair and just employment practices, our team operates under the guidelines of the Central and State Government, adhering to regulations such as the Minimum Wages Act, Employees State Insurance Act, Maternity Benefits Act, Employees Provident Fund Act, Contract Labours Act, and the Shop and Establishment Act.</p>
              <p>In addition to prioritizing employee welfare, Henrima Delta Security Services & Consultancy upholds its financial responsibilities. We have diligently paid service tax, professional tax, and other applicable taxes, contributing to our commitment to transparent and lawful business operations. By adhering to these standards, we strive to create an environment of trust and reliability, ensuring our customers receive services that not only meet but exceed their expectations.</p>
            </article>
            <article className="article-left">
              <h3>SPECIALITY IN SECURITY SERVICES</h3>
              <p>At Henrima Delta Security Services & Consultancy, we take pride in delivering excellent and flawless services to our esteemed clients. Our commitment goes beyond merely providing security solutions; we actively engage with our clients, understanding their security concerns and addressing any other bothersome issues they may face.</p>
              <p>Our company motto revolves around delivering good service to our clients, emphasizing the importance of not just meeting but exceeding their expectations. To ensure the highest standards in our operations, we have implemented a comprehensive training program. Unlike focusing solely on corrective measures, our training predominantly covers preventive strategies. By prioritizing prevention, we aim to eliminate or minimize various security risks, including theft, pilferage, connivance, espionage, sabotage, and more.</p>
              <p>This proactive approach not only sets us apart in the security services industry but also reflects our dedication to providing a secure environment for our clients. Through our commitment to continuous improvement and a focus on prevention, Henrima Delta Security Services & Consultancy stands as a trusted partner, dedicated to the safety and satisfaction of our clients.</p>
            </article>
          </articles>
          
        </div>
      </>
    );
  };
  
  export default About;