import { Helmet } from "react-helmet";

const Blogs = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Blogs</title>
          <meta name="description" content="React application" />
        </Helmet>
      </>
    );
  };
  
  export default Blogs;